import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useCart } from '../../context/cart';
import { getCart } from "../../services/sales/getCart";
import { useAddress } from '../../context/address';
import { payOrder } from '../../services/payments/payOrder';
import { useParams } from 'react-router';
import { EditAlert } from '../../../src/pages/ProductDetail/AddOrEditAlert/Edit';

export default function ChannelModal(props) {
    const { deliveryAddress } = useAddress();
    const { storeId, clientId } = useParams();
    const [locations, setLocations] = useState([]);
    const [address,] = deliveryAddress;
    const { cart } = useCart();
    const [, setModalChannels] = useState(false);
    const [show,] = useState(true);
    const { t } = useTranslation();

    let params = {
        channelId: cart.channelId,
        username: locations?.client?.username,
        text: 'CONTINUE_ORDER',
        idSale: cart.id,
        paymentMethod: 'card',
        address: null,
        location: {
            lat: 0,
            long: 0,
        },
        deliveryStatus: 0,
    }
    useEffect(() => {
        getCart(storeId, clientId).then((location) => {
            setLocations(location);
        });
    }, [storeId, clientId]);
    const payWithMercado = async () => {
        if (cart.clientId === 'qrcode') {
            setModalChannels(true)
        } else {
            toast.success(t('payment.paySuccess'));
            if (locations?.location?.coordinates[0] !== 0) {
                params = {
                    ...params,
                    location: {
                        lat: locations?.location?.coordinates[0],
                        long: locations?.location?.coordinates[1],
                    },
                    address: address.additionalInfo ? `${locations?.location?.address}. ${address.additionalInfo}` : locations?.location?.address,
                    deliveryStatus: 1
                }
            }
            const url = await payOrder(params);
            window.open('https://'+url, '_blank');
        }
        sessionStorage.removeItem('cart');
    }

    return <>
        <EditAlert 
        show={show} 
        message={t('payment.paySuccess')} 
        title={t('cart.return-channel')}
        backChannel={() => { payWithMercado() }} />
    </>
}

ChannelModal.propTypes = {
    show: propTypes.bool,
    onClose: propTypes.func,
}