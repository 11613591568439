import React, { useState, useEffect, useRef } from 'react';
import { HomeCategories, HomePageContainer, HomePromos } from '../style';
import ImageGallery from 'react-image-gallery';
import './home-page.scss';
import propTypes from 'prop-types';
import { HomeImages } from './HomeImages';
import { getPromotions } from '../../../services/getPromotions';
import { useParams } from 'react-router';
import { useRouteNavigation } from "../../../hooks/useRouteNavigation";
import "../../../styles/home-image-gallery.scss";

export const HomePage = ({ categories, showInfo }) => {
    const [promotions, setPromotions] = useState([]);
    const { storeId } = useParams();
    const goToRoute = useRouteNavigation();
    const refImage = useRef(null);

    useEffect(() => {
        getPromotions(storeId)
            .then(setPromotions);
    }, [storeId]);

    const images = promotions.map((img) => {
        return {
            original: img.imageUrl[0],
            description: img.promotionDescription,
            catId: img.categoriesProduct[0]?._id,
            prodId: img._id,
        }
    });

    const catAndProd = () => {
        const index = refImage.current.getCurrentIndex();
        const catProd = images[index];

        if (catProd) {
        goToRoute(`/category/${catProd.catId}/products/${catProd.prodId}`);
        }
    };

    return (
        <HomePageContainer>
            {promotions && promotions.length > 0 && (
                <HomePromos>
                    <ImageGallery
                        ref={refImage}
                        items={images}
                        additionalClass="image"
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showNav={false}
                        showBullets={!showInfo}
                        autoPlay
                        slideDuration={450}
                        onClick={ catAndProd }
                    />
                </HomePromos>
            )}
            <HomeCategories>
                <HomeImages categories={categories} />
            </HomeCategories>
        </HomePageContainer >
    );
};

HomePage.propTypes = {
    categories: propTypes.array,
    showInfo: propTypes.bool,
}