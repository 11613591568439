import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getProduct } from '../../services/products/getProduct';
import ProductDetailHeader from './ProductDetailHeader';
import { ProductDetailPage } from './ProductDetailPage';
import { useCart } from '../../context/cart';
import { ProductDetailComponent } from './style';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { useRouteNavigation } from '../../hooks/useRouteNavigation';
import ImageGallery from 'react-image-gallery';
import './product-page.scss';
import { useImages } from '../../context/images';
import { EditAlert } from '../ProductDetail/AddOrEditAlert/Edit';

export default function ProductDetail() {
    const { contextImageGallery } = useImages();
    const [images, setImages] = contextImageGallery;
    const { productId } = useParams();
    const { t } = useTranslation();
    const { addProduct } = useCart();
    const [product, setProduct] = useState({});
    const [enableAdd, setEnableAdd] = useState(true);
    const goToRoute = useRouteNavigation();
    const [show, setShow] = useState(false);

    useEffect(() => {
        getProduct(productId)
            .then(setProduct);
    }, [productId]);

    const notifyProductAdded = (name) => toast.success(t('detail.productAdded', { product: name }));

    const addToCart = (groups, comments, quantity) => {
        addProduct({
            id: product._id,
            name: product.name,
            description: product.descriptionProduct,
            price: product.salePrice,
            purchase: product.purchasePrice,
            quantity: quantity,
            optionsGroup: groups,
            comments: comments
        }).then(() => {
            notifyProductAdded(product.name);
            setEnableAdd(false);
    
            setTimeout(function () {
                setImages({});
                goToRoute('/home');
            }, 1500);
            }).catch (error => {
                setShow(true);
            }) 
    }
    const productImages = product?.imageUrl || [];
    const imagesGallery = productImages.map((original) => ({ original }));
    const selectedImages = images.imgs?.map((original) => ({ original })) || [];

    return <ProductDetailComponent>
        { show === false ?
        <>
        {productImages.length > 0 && (<>
            <ProductDetailHeader productName={product.name} productImg={images[0]} />
            <div>
                {
                    <ImageGallery items={selectedImages?.length > 0 ? selectedImages : imagesGallery}
                        additionalClass="image"
                        width="500"
                        height="600"
                        showThumbnails={false}
                        showFullscreenButton={true}
                        showPlayButton={false}
                        showNav={true}
                        autoPlay
                        slideDuration={450}
                    />
                }
            </div>
        </>)}
        {
        Object.keys(product).length > 0
            && <ProductDetailPage
                name={product.name}
                description={product.descriptionProduct}
                active={product.active}
                optionsGroup={product.optionsGroup.filter((group) => group.opciones.length > 0)}
                enableButton={enableAdd}
                buttonTitle={t('detail.addToCart')}
                onClick={(groups, comments, quantity) => addToCart(groups, comments, quantity)}
            />
        }
        </>
        :
        <EditAlert show={show} message={t('detail.productAddedError')} />
    }
    </ProductDetailComponent>;
}
