import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import Input from '../../components/Input';
import PageHeader from '../../components/PageHeader';
import Select from '../../components/Select';
import { useCart } from '../../context/cart';
import ChannelModal from '../Cart/ChannelModal';
import { codes } from '../../helpers/phoneCodes';
import { makeTransaction } from '../../services/payments/makeTransaction';
import { AddCardBody, AddCardContainer, AddCardInfo, CardInfo, ErrorMessage, InputGroup, InputSubgroup, InputWrapper, InputWrapperShort, InputWrapperWide } from './style';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { payOrder } from '../../services/payments/payOrder';
import { useGoBack } from '../../hooks/useGoBack';
import DateValidation from './dateValidation';
import { useShop } from '../../context/shop';
import { useAddress } from '../../context/address';
import { EditAlert } from '../../../src/pages/ProductDetail/AddOrEditAlert/Edit';

export default function PaymentInfo() {
    const { register, handleSubmit, formState, control, setError } = useForm();
    const { errors } = formState;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { shop, deliveryOption } = useShop();
    const { deliveryAddress } = useAddress();
    const [delivery,] = deliveryOption;
    const [address,] = deliveryAddress;
    const [modalChannels, setModalChannels] = useState(false);
    const { cart } = useCart();
    const goBack = useGoBack();
    const [show, setShow] = useState(false);


    const notifySuccess = () => toast.success(t('payment.paySuccess'));
    const notifyError = () => toast.error(t('payment.payFail'));

    const createTokenConekta = (cardInfo) => {
        return new Promise((res) => {
            window.Conekta.Token.create(cardInfo, (token) => res(token));
        });
    };
    const conekta = shop?.paymentMethod.find(({ key }) => key === 'conekta');

    const onSubmit = async (data) => {
        if(conekta && conekta.service) {
            window.Conekta.setPublicKey(conekta.publicKey);
    
            const validCard = window.Conekta.card.validateNumber(data.number);
            const validExpiration = window.Conekta.card
                .validateExpirationDate(+data.exp_month, data.exp_year);
            const validCVC = window.Conekta.card.validateCVC(data.cvc);
    
    
            if (!validCard) {
                setError('number', { message: t('validations.invalidCard') });
            }
    
            if (!validExpiration) {
                setError('exp_year', { message: t('validations.invalidDate') });
            }
    
            if (!validCVC) {
                setError('cvc', { message: t('validations.invalidCvc') });
            }
    
            if (validCard && validExpiration && validCVC) {
    
                const cardInfo = {
                    card: {
                        number: data.number,
                        name: data.name,
                        exp_year: data.exp_year,
                        exp_month: +data.exp_month,
                        cvc: data.cvc,
                    },
                };
    
                const phoneNumber = `+${data.code.value}${data.phone}`;
                try {
                    setLoading(true);
                    setShow(true);
                    const token = await createTokenConekta(cardInfo);
                    const result = await makeTransaction(token, { email: data.email, name: data.name, phone: phoneNumber }, cart.id, 'conekta');
                    console.log(result);
    
                    if (cart.clientId === 'qrcode') {
                        setLoading(false);
                        notifySuccess();
                        setModalChannels(true);
                    } else {
                        notifySuccess();
                        let params = {
                            channelId: cart.channelId, 
                            username: cart.clientUsername,
                            text: 'CONTINUE_ORDER', 
                            idSale: cart.id, 
                            paymentMethod: 'card',
                            location: {
                                lat: address.lat,
                                long: address.lng,
                            },
                            address: address.additionalInfo ? `${address.description}. ${address.additionalInfo}` : address.description,
                            deliveryStatus: 0,
                        }
                        if (delivery) {
                            params = {
                                ...params,
                                location: {
                                    lat: address.lat,
                                    long: address.lng,
                                },
                                address: address.additionalInfo ? `${address.description}. ${address.additionalInfo}` : address.description,
                                deliveryStatus: 1
                            }
                        }
                        const url = await payOrder(params);
                        window.open('https://'+url, '_blank');
                    }
                } catch (error) {
                    setLoading(false);
                    console.log(error);
                    notifyError();
                };
            }
        }
    }
    const backChannel = async () => {
        const url = await payOrder();
        window.open(`https://${url}`, '_blank');
    }
    // return 

    return <AddCardContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                type="info"
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {loading ? (
                <EditAlert 
                show={show} 
                message={t('payment.paySuccess')} 
                title={t('cart.return-channel')}
                backChannel={() => { backChannel() }} />
            ) :
            <>
            <AddCardBody>
                <PageHeader title={t('payment.info')} cancelButton onClick={goBack} />
                <AddCardInfo>
                    <InputWrapper>
                        <Input
                            label={t('payment.cardName')}
                            name="name"
                            autoComplete="off"
                            error={errors.name}
                            {...register("name", { required: true })}
                        />
                        {errors.name && <ErrorMessage>{t('validations.requiredField')}</ErrorMessage>}
                    </InputWrapper>
                    <InputWrapper>
                        <Input
                            label={t('payment.cardNumber')}
                            name="number"
                            maxLength={16}
                            error={errors.number}
                            {...register("number", { required: true, maxLength: 16 })}
                        />
                        {errors.number && errors.number?.type === 'required' && <ErrorMessage>{t('validations.requiredField')}</ErrorMessage>}
                        {errors.number && <ErrorMessage>{errors.number.message}</ErrorMessage>}
                    </InputWrapper>
                    <CardInfo>
                        <InputGroup>
                            <InputWrapperWide>
                                <InputSubgroup>
                                    <Input
                                        label={t('payment.expYear')}
                                        name="exp_year"
                                        maxLength={4}
                                        error={errors.exp_year || errors.exp_month}
                                        {...register("exp_year", { required: true, maxLength: 4, min: 2022 })}
                                    />
                                    <Input
                                        label={t('payment.expMonth')}
                                        name="exp_month"
                                        maxLength={2}
                                        error={errors.exp_month || errors.exp_year}
                                        {...register("exp_month", { required: true, maxLength: 2, min: 1, max: 12 })}
                                    />
                                </InputSubgroup>
                                {<DateValidation errors={errors} />}
                            </InputWrapperWide>
                            <InputWrapperShort>
                                <Input
                                    label={t('payment.cvc')}
                                    name="cvc"
                                    type="password"
                                    maxLength={3}
                                    width="80px"
                                    error={errors.cvc}
                                    {...register("cvc", { required: true, maxLength: 3 })}
                                />
                                {(errors.cvc && errors.cvc?.type === "required") && <ErrorMessage>{t('validations.cvcRequired')}</ErrorMessage>}
                                {errors.cvc && <ErrorMessage>{errors.cvc.message}</ErrorMessage>}
                            </InputWrapperShort>
                        </InputGroup>
                    </CardInfo>
                    <InputGroup>
                        <InputWrapperShort>
                            <Controller
                                control={control}
                                name="code"
                                rules={{ required: true }}
                                error={errors.code}
                                render={({ field }) => {
                                    return <Select
                                        label={t('payment.code')}
                                        options={codes}
                                        {...field}
                                    />
                                }}
                            />
                            {errors.code && <ErrorMessage>{t('validations.requiredField')}</ErrorMessage>}

                        </InputWrapperShort>
                        <InputWrapperWide>
                            <Input
                                label={t('payment.phone')}
                                name="phone"
                                maxLength={10}
                                error={errors.phone}
                                {...register("phone", {
                                    required: true, maxLength: 10, pattern: {
                                        value: /^[0-9]+$/,
                                        message: t('validations.invalidPhone'),
                                    },
                                })}
                            />
                            {errors.phone && errors.phone?.type === "required" && <ErrorMessage>{t('validations.requiredField')}</ErrorMessage>}
                            {errors.phone && errors.phone?.type === "pattern" && <ErrorMessage>{errors.phone.message}</ErrorMessage>}
                        </InputWrapperWide>
                    </InputGroup>
                    <InputWrapper>
                        <Input
                            label={t('payment.email')}
                            name="email"
                            error={errors.email}
                            {...register("email", {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: t('validations.invalidEmail')
                                }
                            })}

                        />
                        {errors.email && errors.email?.type === "required" && <ErrorMessage>{t('validations.requiredField')}</ErrorMessage>}
                        {errors.email && errors.email?.type === "pattern" && <ErrorMessage>{errors.email.message}</ErrorMessage>}
                    </InputWrapper>
                </AddCardInfo >
            </AddCardBody >
            <Footer>
                <Button title={t('payment.pay')} />
                {cart.clientId === 'qrcode' && <ChannelModal
                    show={modalChannels}
                    onClose={() => { setModalChannels(false) }} />}
            </Footer>
            </>                
            }
        </form>
    </AddCardContainer >;

}
